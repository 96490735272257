import { NumberLocale } from 'yup/lib/locale';

export interface DirectorInfo {
  rewardPoints: number;
  shoppingPoints: number;
  ulxPoints: number;
  buybackUlx: number;
  myLifePoints: number;
  pendingWithdrawals: number;
  paidWithdrawals: number;
  totalWithdrawals: number;
  withdrawalsCount: number;
  totalEarnings: number;
  totalRewards: number;
  bankTransferPurchases: number;
  bankTransferCount: number;
  shoppingPointsPurchases: number;
  shoppingPointsCount: number;
  totalPurchases: number;
  payoutPercent: number;
  teamBonusesTotal: number;
  directBonusesTotal: number;
  matchingBonusesTotal: number;
  globalPoolBonusesTotal: number;
  topPerformerPoolBonusesTotal: number;
  cashbackBonusesTotal: number;
  packageRankRewardsTotal: number;
  myLifePointsPurchases: number;
  myLifePointsCount: number;
  rewardPointsPurchases: number;
  loyaltyPointsPurchases: number;
  mixPurchases: number;
  rewardPointsCount: number;
  loyaltyPointsCount: number;
  bankTransferLicencePurchases: number;
  rewardPointsLicencePurchases: number;
  shoppingPointsLicencePurchases: number;
  mixLicencePurchases: number;
  totalLicencePurchases: number;
  bankTransferLicenceCount: number;
  rewardPointsLicenceCount: number;
  shoppingPointsLicenceCount: number;
  mixLicenceCount: number;
  mixCount: number;
  totalPurchasesWithoutLP: number;
  activationVoucherPurchases: {
    bankTransferPurchases: number;
    bankTransferActivationVoucherCount: number;
    rewardPointsPurchases: number;
    rewardPointsActivationVoucherCount: number;
    shoppingPointsPurchases: number;
    shoppingPointsActivationVoucherCount: number;
    mixPurchases: number;
    mixActivationVoucherCount: number;
    totaPurchases: number;
  };
  eventTicketPurchases: {
    bankTransferPurchases: number;
    bankTransferActivationVoucherCount: number;
    rewardPointsPurchases: number;
    rewardPointsActivationVoucherCount: number;
    shoppingPointsPurchases: number;
    shoppingPointsActivationVoucherCount: number;
    mixPurchases: number;
    mixActivationVoucherCount: number;
    totaPurchases: number;
  };
  trevolutionSubscriptionDirectBonusesTotal: number;
  trevolutionSubscriptionPurchases: {
    bankTransferPurchases: number;
    bankTransferActivationVoucherCount: number;
    rewardPointsPurchases: number;
    rewardPointsActivationVoucherCount: number;
    shoppingPointsPurchases: number;
    shoppingPointsActivationVoucherCount: number;
    mixPurchases: number;
    mixActivationVoucherCount: number;
    totaPurchases: number;
    usdcActivationVoucherCount: number;
    usdcPurchases: number;
  };
  packages: [
    {
      prices: {
        price: number;
        sum: number;
        count: number;
      }[];
      total: number;
      title: string;
    }
  ];
  licences: [
    {
      price: number;
      sum: number;
      count: number;
    }
  ];
}

export interface DirectorInfoState {
  data: DirectorInfo;
  loading: boolean;
  loadingExportWithdrawals: boolean;
  loadingExportPackages: boolean;
  loadingExportLicences: boolean;
  totalCount: number;
  exportWithdrawalsData: any;
  exportLicencesData: any;
  exportPackagesData: any;
  exportPaymentHubs: any;
  exportStackingHubs: any;
  exportGamingHubs: any;
  exportBigWhaleHubs: any;
  exportTravelHubs: any;
  exportFlipMeHubs: any;
  exportTradingHubs: any;
  exportAkashaHubs: any;
}

export interface Address {
  id: number;
  country: string;
  countryCode: string;
}

export interface User {
  id: number;
  name: string;
  surname: string;
  address: Address;
}

export interface WithdrawalsExport {
  id: number;
  dateCreated: string;
  datePaid: string;
  amount: number;
  feeAmount: number;
  paidAmount: number;
  wallet: string;
  networkType: number;
  status: number;
  user: User;
}

export enum HubsEnum {
  STACKING = 1,
  GAMING = 2,
  PAYMENT = 4,
  TRADING = 5,
  BIG_WHALE = 6,
  MEME_BONUS_ACTIVATION = 7,
  TRAVELUTION = 8,
  FLIP_ME = 9,
  AKASHA = 10
}
