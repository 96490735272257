export interface TrevolutionSubscription {
  id: number;
  name: string;
  price: number;
  daysDuration: number;
}

export interface User {
  id: number;
  email: string;
  profileImageUrl: string;
  name: string;
  surname: string;
  dateCreated: string;
}

export enum OrderType {
  ORDER = 1,
  PACKAGE,
  LICENCE,
  COMBO,
  TRAVELUTION_SUBS = 7
}

export interface TravelutionSubscriptionType {
  id: number;
  price: number;
  status: number;
  dateCreated: string;
  dateEnd: string;
  datePaid: string;
  networkType: number;
  paymentAddress: string;
  dateLimitForAutoStake: string;
  trevolutionSubscription: TrevolutionSubscription;
  user: User;
  orderType: OrderType;
}

export enum OpenedModal {
  CLOSED,
  MARK_AS_PAID,
  STORNO
}

export enum TravelutionBOStatus {
  PENDING = 1,
  PAID,
  CANCELED,
  CANCELED_BY_USER
}
