import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../../../helpers/api_helper';

export const getTansfersToEarnBet = createAsyncThunk(
  'getTansfersToEarnBet/fetch',
  async (userId: number | null) => {
    const response = await axiosInstance.get(`/mlm/user/accounting/transfers`, {
      params: { userId, countPerPage: 200, numberOfPage: 1 }
    });

    return response.data.data;
  }
);
