export interface PackageOrder {
  packageId: number;
  name: string;
  price: number;
  teamBonusPercent: number | null;
  order: number;
  stakeRatio: number;
  weekCapRuleLimit: number | null;
  volumePoints: number | null;
}

export interface PackageRank {
  packageRankId: number;
  name: string;
}

export interface User {
  id: number;
  email: string | null;
  profileImageUrl: string | null;
  name: string | null;
  surname: string | null;
  dateCreated: string;
}

export enum PackageStatus {
  PENDING = 1,
  PAID,
  CANCELED,
  CANCELED_BY_USER
}

export enum CalculationStatus {
  CALCULATE = 1,
  SKIP_WITH_HUB_CREATION,
  SKIP_WITHOUT_HUB_CREATION,
  SKIP_WITH_HUB_CREATION_ADD_PO_PV,
  SKIP_WITHOUT_HUB_CREATION_ADD_PO_PV,

  SKIP_WITH_HUB_CREATION_ADD_PO,
  SKIP_WITHOUT_HUB_CREATION_ADD_PO,
  SKIP_WITH_HUB_CREATION_ADD_PV,
  SKIP_WITHOUT_HUB_CREATION_ADD_PV,
  LP_TRADING_ADD_PO
}

export enum OpenedModal {
  CLOSED,
  RECALCULATE_TOTAL_BONUS,

  MARK_AS_PAID,
  MARK_AS_PAID_WITH_HUB_NO_CALCULATION_NO_POPV,
  MARK_AS_PAID_NO_HUB_NO_CALCULATION_NO_POPV,
  MARK_AS_PAID_WITH_HUB_NO_CALC_WITH_POPV,
  MARK_AS_PAID_NO_HUB_NO_CALC_WITH_POPV,

  MARK_AS_PAID_WITH_HUB_NO_CALCULATION_WITH_PO,
  MARK_AS_PAID_NO_HUB_NO_CALCULATION_WITH_PO,
  MARK_AS_PAID_WITH_HUB_NO_CALCULATION_WITH_PV,
  MARK_AS_PAID_NO_HUB_NO_CALCULATION_WITH_PV,
  LP_TRADING_ADD_PO,

  STORNO,
  RESEND_PAYMENT_INSTRUCTIONS
}

export enum OrderType {
  ORDER = 1,
  PACKAGE,
  LICENCE,
  COMBO
}

export enum NetworkType {
  BEP_20 = 1,
  TRC_20,
  Ultron
}

export interface UserPackages {
  id: number;
  price: number;
  shoppingPoints: number;
  donationPoints: number;
  teamBonusPercent: number | null;
  status: number;
  dateCreated: string;
  datePaid: string | null;
  pdfUrl: string | null;
  promoFormatUrl: string | null;
  networkType: NetworkType;
  paymentAddress: string | null;
  dateLimitForAutoStake: string | null;
  calculationStatus: CalculationStatus;
  package: PackageOrder;
  packageRank: PackageRank;
  user: User;
  orderType: OrderType;
}

export enum CurrencyType {
  BANK_TRANSFER = 1,
  SHOPPING_POINTS,
  REWARD_POINTS,
  LOYALTY_POINTS,
  MIX,
  USDC = 7
}
