import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import TableContainer from '../../../Components/Common/TableContainer';
import { PageHeader } from '../../../Components/Custom/PageHeader';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { paramsForExport, setDocumentTitle } from '../../../helpers/helpers';
import { columns } from './columns';
import {
  exportHeaders,
  exportTransfers,
  getTansfersToEarnBet,
  removeTransfersForExport
} from './slice';

import { Pagination } from '../../../Components/Custom/pagination';
import { CsvExport } from '../../../Components/Custom/CsvExport';
import { Filters } from './filters';

const PAGE_TITLE = 'Transfer to EarnBet';

const TransferToEarnBetPage = () => {
  const INITIAL_PARAMS: {
    sortBy: string;
    sortOrder: string;
    countPerPage: string;
    numberOfPage: string;
  } = {
    sortBy: 'dateCreated',
    sortOrder: 'DESC',
    countPerPage: '10',
    numberOfPage: '1'
  };

  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams(INITIAL_PARAMS);
  const { loading, loadingExport, data, dataForExport, totalCount } =
    useAppSelector((state) => state.tansfersToEarnBet);

  useEffect(() => {
    dispatch(removeTransfersForExport());

    dispatch(getTansfersToEarnBet(searchParams));
  }, [searchParams]);

  setDocumentTitle(PAGE_TITLE);

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title={PAGE_TITLE} pageTitle='Transfer To EarnBet' />
        <Row>
          <Col lg={12}>
            <Card id='orderList'>
              <PageHeader
                loading={loading || loadingExport}
                title={PAGE_TITLE}
                initialParams={INITIAL_PARAMS}
                setSearchParams={setSearchParams}
                actions={
                  <>
                    <CsvExport
                      action={() =>
                        dispatch(exportTransfers(paramsForExport(searchParams)))
                      }
                      data={dataForExport}
                      headers={exportHeaders}
                      disabled={loadingExport}
                      fileName={`withdrawals-${moment().format('DD-MM-YYYY')}`}
                      removeDataAction={removeTransfersForExport}
                    />
                  </>
                }
              />
              <CardBody className='pt-0'>
                <Filters params={searchParams} setParams={setSearchParams} />
                <TableContainer
                  columns={columns}
                  data={data || []}
                  divClass='table-responsive table-card mb-1'
                  tableClass='align-middle table-nowrap'
                  theadClass='table-light text-muted'
                />
                <Pagination
                  totalCount={totalCount}
                  data={data}
                  params={searchParams}
                  setParams={setSearchParams}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TransferToEarnBetPage;
