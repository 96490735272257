import { UncontrolledTooltip } from 'reactstrap';
import {
  currencyTypeRender,
  handleValidDate,
  handleValidTime,
  usdFormatter
} from '../../../helpers/helpers';
import { CurrencyType, NetworkType } from '../PackageOrders/types';

import moment from 'moment';
import TravelutionsBOActions from './actions';

export const columns = [
  {
    id: 1,
    Header: 'Subscription ID',
    accessor: 'id',
    Cell: (cell: any) => {
      return cell?.value;
    }
  },
  {
    id: 2,
    Header: 'User ID',
    accessor: 'user',
    Cell: (cell: any) => {
      return cell.value.id;
    }
  },

  {
    id: 3,
    Header: 'User',
    accessor: 'user',
    Cell: (cell: any) => {
      return (
        <>
          {cell.value.name} {cell.value.surname}
        </>
      );
    }
  },

  {
    id: 4,
    Header: 'Subs name',
    accessor: 'trevolutionSubscription',
    Cell: (cell: any) => {
      return cell.row.original.trevolutionSubscription.name;
    }
  },
  {
    id: 5,
    Header: 'Amount',
    accessor: 'package',
    Cell: (cell: any) => {
      return cell.row.original.price
        ? usdFormatter.format(cell.row.original.price)
        : '---';
    }
  },
  {
    id: 6,
    Header: 'Payment address',
    accessor: 'paymentAddress',
    Cell: (cell: any) => {
      return cell?.value;
    }
  },
  {
    id: 7,
    Header: 'Payment type',
    accessor: 'currencyType',
    Cell: (cell: any) => {
      return (
        <>
          {cell?.value === CurrencyType.MIX ? (
            <>
              {currencyTypeRender(cell.row.original.currencyType)}{' '}
              <i
                id={`tooltipTop${cell?.row?.original?.id}`}
                className='ri-information-line'></i>
              <UncontrolledTooltip
                placement='top'
                target={`tooltipTop${cell?.row?.original?.id}`}
                className='mytooltip'>
                {cell?.row?.original?.payments.map(
                  (payment: any, i: number) => (
                    <tr key={i}>
                      <td>
                        {payment.amount}{' '}
                        {currencyTypeRender(payment.currencyType)}
                      </td>
                      <td>
                        {moment(payment.dateCreated).format(
                          'YYYY-MM-DD HH:mm:ss'
                        )}
                      </td>
                    </tr>
                  )
                )}
                {cell.row.original?.amountToPay > 0 && (
                  <tr>
                    <td>
                      {cell.row.original?.amountToPay}{' '}
                      {cell.row.original?.currencyAmountToPay}
                    </td>
                    <td>PENDING</td>
                  </tr>
                )}
              </UncontrolledTooltip>
            </>
          ) : (
            currencyTypeRender(cell.row.original.currencyType)
          )}
        </>
      );
    }
  },
  {
    id: 8,
    Header: 'Network type',
    accessor: 'networkType',
    Cell: (cell: any) => {
      switch (cell.value) {
        case NetworkType.BEP_20:
          return <div>BEP-20</div>;

        case NetworkType.TRC_20:
          return <div>TRC-20</div>;

        case NetworkType.Ultron:
          return <div>Ultron</div>;

        default:
          return <div></div>;
      }
    }
  },
  {
    id: 9,
    Header: 'Order Date',
    accessor: 'dateCreated',
    Cell: (cell: any) => (
      <>
        {handleValidDate(cell.row.original.dateCreated)},
        <small className='text-muted'>
          {' '}
          {handleValidTime(cell.row.original.dateCreated)}
        </small>
      </>
    )
  },

  {
    id: 10,
    Header: 'Payment Date',
    accessor: 'datePaid',
    Cell: (cell: any) => {
      if (cell.value) {
        return (
          <>
            {handleValidDate(cell.value)},
            <small className='text-muted'> {handleValidTime(cell.value)}</small>
          </>
        );
      }
    }
  },
  {
    id: 11,
    Header: 'Start date',
    accessor: 'dateStart',
    Cell: (cell: any) => {
      if (cell.value) {
        return (
          <>
            {handleValidDate(cell.row.original.dateStart)},
            <small className='text-muted'>
              {' '}
              {handleValidTime(cell.row.original.dateStart)}
            </small>
          </>
        );
      }
    }
  },
  {
    id: 12,
    Header: 'End date',
    accessor: 'dateEnd',
    Cell: (cell: any) => {
      if (cell.value) {
        return (
          <>
            {handleValidDate(cell.row.original.dateEnd)},
            <small className='text-muted'>
              {' '}
              {handleValidTime(cell.row.original.dateEnd)}
            </small>
          </>
        );
      }
    }
  },

  {
    id: 13,
    Header: 'Status',
    accessor: 'status',
    Cell: (cell: any) => {
      console.log();
      if (cell.value === 1) {
        return (
          <span className='badge text-uppercase badge-soft-warning'>
            Pending
          </span>
        );
      } else if (
        cell.value === 2 &&
        cell.row.original.shouldCalculateDirectBonus
      ) {
        return (
          <span className='badge text-uppercase badge-soft-success'>
            Paid with DB bonus, No POPV
          </span>
        );
      } else if (
        cell.value === 2 &&
        !cell.row.original.shouldCalculateDirectBonus
      ) {
        return (
          <span className='badge text-uppercase badge-soft-success'>
            Paid withouth DB bonus, No POPV
          </span>
        );
      } else {
        return (
          <span className='badge text-uppercase badge-soft-danger'>
            Canceled
          </span>
        );
      }
    }
  },

  {
    id: 14,
    Header: 'Actions',
    accessor: 'id',
    Cell: (cell: any) => {
      if (cell?.row?.original?.isCancelable) {
        return <TravelutionsBOActions {...cell} />;
      }
    }
  }
];
