import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../helpers/api_helper';
import { TransferState, TransferType } from './types';
import { toast } from 'react-toastify';
import {
  formatUSDT,
  handleValidDate,
  handleValidTime
} from '../../../helpers/helpers';
import { statusForNetwork, statusForTable } from '../DirectorInfo/slice';

export const getTansfersToEarnBet = createAsyncThunk(
  'getTansfersToEarnBet/fetch',
  async (params: URLSearchParams) => {
    const response = await axiosInstance.get(`/earnbet/transfers`, {
      params
    });

    const data = {
      count: response.data.data.count,
      transfers: response.data.data.userEarnbetTopups
    };

    return data;
  }
);

export const exportTransfers = createAsyncThunk(
  'getTansfersToEarnBet/exportTransfers',
  async (params: URLSearchParams) => {
    const response = await axiosInstance.get(`/earnbet/transfers/export`, {
      params
    });

    const dataForExport = response.data.data.userEarnbetTopups.map(
      (w: TransferType) => ({
        id: w.id,
        userId: w.user.id,
        user: `${w.user.name} ${w.user.surname}`,
        paymentAddress: w.paymentAddress || '',
        paidAmount: `${w.price ? formatUSDT.format(w.price) : ''}`,
        receivedAmount: `${
          w.receivedAmount ? formatUSDT.format(w.receivedAmount) : ''
        }`,

        network: statusForNetwork(w?.networkType),
        dateCreated: w.dateCreated
          ? `${handleValidDate(w.dateCreated)}, ${handleValidTime(
              w.dateCreated
            )}`
          : '',
        datePaid: w.datePaid
          ? `${handleValidDate(w.datePaid)}, ${handleValidTime(w.datePaid)}`
          : '',
        type: w.type,
        networkCurrencyType: w.networkCurrencyType,
        status: statusForTable(w.status)
      })
    );

    return dataForExport;
  }
);

export const exportHeaders = [
  { label: 'ID', key: 'id' },
  { label: 'User ID', key: 'userId' },
  { label: 'User', key: 'user' },
  { label: 'Wallet Address', key: 'paymentAddress' },
  { label: 'Network', key: 'network' },
  { label: 'Paid Amount', key: 'paidAmount' },
  { label: 'Received Amount', key: 'receivedAmount' },
  { label: 'Date Created', key: 'dateCreated' },
  { label: 'Date Paid', key: 'datePaid' },
  { label: 'Type', key: 'type' },
  { label: 'Currency', key: 'networkCurrencyType' },
  { label: 'Status', key: 'status' }
];

const initialState = {
  data: [],
  totalCount: 0,
  loading: false,
  loadingExport: false,
  dataForExport: []
} as TransferState;

const getTansfersToEarnBetSlice = createSlice({
  name: 'getTansfersToEarnBet',
  initialState,
  reducers: {
    removeTransfersForExport(state) {
      state.dataForExport = [];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getTansfersToEarnBet.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTansfersToEarnBet.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload.transfers as TransferType[];
      state.totalCount = payload.count;
    });
    builder.addCase(getTansfersToEarnBet.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(exportTransfers.pending, (state) => {
      state.loadingExport = true;
    });
    builder.addCase(exportTransfers.fulfilled, (state, { payload }) => {
      state.loadingExport = false;
      state.dataForExport = payload;
    });
    builder.addCase(exportTransfers.rejected, (state) => {
      state.loadingExport = false;
    });
  }
});

export const { removeTransfersForExport } = getTansfersToEarnBetSlice.actions;

export default getTansfersToEarnBetSlice.reducer;
