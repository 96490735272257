import { FC } from 'react';
import { RenderFilters } from '../../../Components/Custom/RenderFilters';
import { RenderSorting } from '../../../Components/Custom/RenderSorting';
import {
  INPUT_TYPE_AMOUNT,
  INPUT_TYPE_DATE_RANGE,
  INPUT_TYPE_SEARCH,
  INPUT_TYPE_SELECT
} from '../../../helpers/helpers';

interface FiltersProps {
  params: URLSearchParams;
  setParams: any;
}

export const Filters: FC<FiltersProps> = ({ params, setParams }) => {
  const statusOptions = [
    { label: 'Wainting For Payment', value: '1' },
    { label: 'Paid', value: '2' }
  ];

  const sortOrderOptions = [
    { label: 'Ascending', value: 'ASC' },
    { label: 'Descending', value: 'DESC' }
  ];

  const sortByOptions = [
    { label: 'Date Created', value: 'dateCreated' },
    { label: 'Status', value: 'status' }
  ];

  const sortingInputs = [
    { param: 'sortBy', type: INPUT_TYPE_SELECT, options: sortByOptions },
    { param: 'sortOrder', type: INPUT_TYPE_SELECT, options: sortOrderOptions }
  ];

  const filterInputs = [
    { param: 'userId', type: INPUT_TYPE_SEARCH, placeholder: 'Search User ID' },
    {
      param: 'userEarnbetTopupId',
      type: INPUT_TYPE_SEARCH,
      placeholder: 'Search EarnBet TopUp ID'
    },
    {
      param: 'paymentAddress',
      type: INPUT_TYPE_SEARCH,
      placeholder: 'Search payment address'
    },

    { param: '', type: INPUT_TYPE_DATE_RANGE },
    {
      param: 'status',
      type: INPUT_TYPE_SELECT,
      placeholder: 'Status',
      options: statusOptions
    }
  ];

  return (
    <>
      <RenderSorting
        sortingInputs={sortingInputs}
        searchParams={params}
        setSearchParams={setParams}
      />
      <RenderFilters
        filterInputs={filterInputs}
        searchParams={params}
        setSearchParams={setParams}
      />
    </>
  );
};
