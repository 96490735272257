import { useState } from 'react';
import {
  DropdownMenu,
  DropdownToggle,
  Spinner,
  UncontrolledDropdown
} from 'reactstrap';
import { TravelutionBOStatus } from '../types';
import {
  MarkTravelutionBoAsPaid,
  MarkTravelutionBoAsPaidModal
} from './markTravelutionBoAsPaid';

import { Storno, StornoModal } from './storno';
import {
  MarkTravelutionBoAsPaidNoPOPV,
  MarkTravelutionBoAsPaidNoPOPVModal
} from './markTravelutionBoAsPaidNoPOPV';

export const TravelutionsBOActions = (cell: any) => {
  const [isOpen, setIsOpen] = useState(0);
  const [isOpen2, setIsOpen2] = useState(0);

  const { id, status } = cell.row.original;

  return (
    <>
      <UncontrolledDropdown>
        <DropdownToggle
          href='#'
          className='btn btn-soft-secondary btn-sm'
          tag='button'>
          <i className='ri-more-fill' />
        </DropdownToggle>

        <DropdownMenu className='dropdown-menu-end'>
          {status === TravelutionBOStatus.PENDING && (
            <MarkTravelutionBoAsPaid setIsOpen={setIsOpen} />
          )}

          {status === TravelutionBOStatus.PENDING && (
            <MarkTravelutionBoAsPaidNoPOPV setIsOpen={setIsOpen2} />
          )}

          {status === TravelutionBOStatus.PENDING && (
            <Storno setIsOpen={setIsOpen} />
          )}
        </DropdownMenu>
      </UncontrolledDropdown>

      {status === TravelutionBOStatus.PENDING && isOpen !== 0 && (
        <MarkTravelutionBoAsPaidModal
          id={id}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      )}

      {status === TravelutionBOStatus.PENDING && isOpen2 !== 0 && (
        <MarkTravelutionBoAsPaidNoPOPVModal
          id={id}
          isOpen={isOpen2}
          setIsOpen={setIsOpen2}
        />
      )}

      {status === TravelutionBOStatus.PENDING && (
        <StornoModal id={id} isOpen={isOpen} setIsOpen={setIsOpen} />
      )}
    </>
  );
};

export default TravelutionsBOActions;
