import { useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import { CustomInput } from '../../../../Components/Custom/CustomInput';
import { Toggle } from '../../../../Components/Custom/Toggle';
import { Modal } from '../../../../Components/Custom/modal';
import { useAppDispatch } from '../../../../app/hooks';
import { ValidateEmail } from '../../../../helpers/helpers';
import { createSuperUserPermissions } from '../slice';

export const CreateAdminUser = () => {
  const dispatch = useAppDispatch();

  const [modal, setModal] = useState(false);

  const [state, setState] = useState({
    name: '',
    surname: '',
    email: '',
    permissions: {
      users: false,
      packageOrders: false,
      ranking: false,
      rankRewards: false,
      withdrawals: false,
      withdrawalsViewOnly: false,
      director: false,
      superAdmin: false,
      teams: false,
      bonuses: false,
      contentBlocks: false,
      eventTickets: false,
      faq: false,
      licences: false,
      packagePaymentAdmin: false,
      extraordinaryBonuses: false,
      techAdmin: false,
      transfers: false
    }
  });

  const handleSubmit = () => {
    if (!state.name) return toast.error('Name not allowed to be empty');
    if (!state.surname) return toast.error('Surname not allowed to be empty');
    if (!ValidateEmail(state.email))
      return toast.error('Email must be valid email');

    dispatch(createSuperUserPermissions(state));
    handleCloseModal();
  };

  const handleCloseModal = () => {
    setModal(false);
  };

  const handleChange = (e: any) => {
    const { name } = e.target;
    setState((prev: any) => {
      const copy = { ...prev };

      copy.permissions[name as keyof typeof prev.permissions] =
        !copy.permissions[name as keyof typeof prev.permissions];

      if (name === 'withdrawals' && copy.permissions.withdrawals) {
        copy.permissions.withdrawalsViewOnly = false;
      } else if (
        name === 'withdrawalsViewOnly' &&
        copy.permissions.withdrawalsViewOnly
      ) {
        copy.permissions.withdrawals = false;
      }

      return copy;
    });
  };

  const handleChangeInput = (e: any) => {
    const { name, value } = e.target;
    setState((prev) => {
      const copy = { ...prev };
      copy[name as keyof typeof state] = value;
      return copy;
    });
  };

  return (
    <>
      <button
        type='button'
        className='btn btn-primary'
        onClick={() => setModal(!modal)}>
        <i className='ri-file-add-fill align-bottom me-1'></i> Add User
        Permissions
      </button>

      <Modal
        title='Add User Permissions'
        isOpen={modal}
        setIsOpen={() => setModal(!modal)}
        actions={
          <>
            <>
              <Button
                color='primary'
                outline
                onClick={handleCloseModal}
                className='m-1'>
                Cancel
              </Button>
              <Button color='primary' className='m-1' onClick={handleSubmit}>
                Save
              </Button>
            </>
          </>
        }>
        <>
          <CustomInput
            onChange={handleChangeInput}
            value={state.name}
            name='name'
            placeholder='Name'
          />
          <CustomInput
            onChange={handleChangeInput}
            value={state.surname}
            name='surname'
            placeholder='Surname'
          />
          <CustomInput
            onChange={handleChangeInput}
            value={state.email}
            name='email'
            placeholder='Email'
            type='email'
          />

          <div className='d-flex justify-content-lg-between'>
            <p>Super Admin</p>
            <Toggle
              checked={state?.permissions?.superAdmin}
              name='superAdmin'
              onChange={handleChange}
            />
          </div>
          <div className='d-flex justify-content-lg-between'>
            <p>Users</p>
            <Toggle
              checked={state?.permissions?.users}
              name='users'
              onChange={handleChange}
            />
          </div>
          <div className='d-flex justify-content-lg-between'>
            <p>Package Orders</p>
            <Toggle
              checked={state?.permissions?.packageOrders}
              name='packageOrders'
              onChange={handleChange}
            />
          </div>
          <div className='d-flex justify-content-lg-between'>
            <p>Ranking</p>
            <Toggle
              checked={state?.permissions?.ranking}
              name='ranking'
              onChange={handleChange}
            />
          </div>
          <div className='d-flex justify-content-lg-between'>
            <p>Rank Reward</p>
            <Toggle
              checked={state?.permissions?.rankRewards}
              name='rankRewards'
              onChange={handleChange}
            />
          </div>
          <div className='d-flex justify-content-lg-between'>
            <p> Withdrawals</p>
            <Toggle
              checked={state?.permissions?.withdrawals}
              name='withdrawals'
              onChange={handleChange}
            />
          </div>
          <div className='d-flex justify-content-lg-between'>
            <p>Transfers to EarnBet</p>
            <Toggle
              checked={state?.permissions?.transfers}
              name='transfers'
              onChange={handleChange}
            />
          </div>

          <div className='d-flex justify-content-lg-between'>
            <p>Withdrawals View Only</p>
            <Toggle
              checked={state?.permissions?.withdrawalsViewOnly}
              name='withdrawalsViewOnly'
              onChange={handleChange}
            />
          </div>
          <div className='d-flex justify-content-lg-between'>
            <p> Director Info</p>
            <Toggle
              checked={state?.permissions?.director}
              name='director'
              onChange={handleChange}
            />
          </div>
          <div className='d-flex justify-content-lg-between'>
            <p> Teams</p>
            <Toggle
              checked={state?.permissions?.teams}
              name='teams'
              onChange={handleChange}
            />
          </div>
          <div className='d-flex justify-content-lg-between'>
            <p> Bonuses</p>
            <Toggle
              checked={state?.permissions?.bonuses}
              name='bonuses'
              onChange={handleChange}
            />
          </div>

          <div className='d-flex justify-content-lg-between'>
            <p> Content Blocks</p>
            <Toggle
              checked={state?.permissions?.contentBlocks}
              name='contentBlocks'
              onChange={handleChange}
            />
          </div>

          <div className='d-flex justify-content-lg-between'>
            <p> Event tickets</p>
            <Toggle
              checked={state?.permissions?.eventTickets}
              name='eventTickets'
              onChange={handleChange}
            />
          </div>

          <div className='d-flex justify-content-lg-between'>
            <p> FAQ</p>
            <Toggle
              checked={state?.permissions?.faq}
              name='faq'
              onChange={handleChange}
            />
          </div>
          <div className='d-flex justify-content-lg-between'>
            <p>Licenses</p>
            <Toggle
              checked={state?.permissions?.licences}
              name='licences'
              onChange={handleChange}
            />
          </div>
          <div className='d-flex justify-content-lg-between'>
            <p>Package Payment Admin</p>
            <Toggle
              checked={state?.permissions?.packagePaymentAdmin}
              name='packagePaymentAdmin'
              onChange={handleChange}
            />
          </div>

          <div className='d-flex justify-content-lg-between'>
            <p>Balance change</p>
            <Toggle
              checked={state?.permissions?.extraordinaryBonuses}
              name='extraordinaryBonuses'
              onChange={handleChange}
            />
          </div>

          <div className='d-flex justify-content-lg-between'>
            <p>Tech Admin</p>
            <Toggle
              checked={state?.permissions?.techAdmin}
              name='techAdmin'
              onChange={handleChange}
            />
          </div>
        </>
      </Modal>
    </>
  );
};
