import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../helpers/api_helper';
import { DirectorInfo, DirectorInfoState, HubsEnum } from './types';
import {
  handleValidDate,
  handleValidTime,
  usdFormatter
} from '../../../helpers/helpers';
import { calculationStatusForTable } from '../PackageOrders/slice';

export const getDirectorInfo = createAsyncThunk(
  'directorInfo/fetch',
  async (params: URLSearchParams) => {
    const plainParams: Record<string, string | null> = {};
    params.forEach((value, key) => {
      plainParams[key] = value === '' ? null : value;
    });

    const response = await axiosInstance.get('/director/info', {
      params: plainParams
    });

    return response.data.data;
  }
);

export const exportWithdrawals = createAsyncThunk(
  'directorInfo/withdrawals',
  async (params: URLSearchParams) => {
    const plainParams: Record<string, string | null> = {};
    params.forEach((value, key) => {
      plainParams[key] = value === '' ? null : value;
    });

    const response = await axiosInstance.get(
      '/director/withdrawals/export',
      plainParams.fromDate !== null && plainParams.toDate !== null
        ? {
            params
          }
        : {}
    );

    const dataForExport = response.data.data.map((po: any) => ({
      id: po?.id,
      user: `${po?.user?.name} ${po?.user?.surname}`,
      country: `${po?.user?.address?.country}`,
      amount: `${po?.amount ? usdFormatter?.format(po?.amount) : ''}`,
      feeAmount: `${po?.feeAmount ? usdFormatter?.format(po?.feeAmount) : ''}`,
      paidAmount: `${
        po?.paidAmount ? usdFormatter?.format(po?.paidAmount) : ''
      }`,
      wallet: po?.wallet || '',
      dateCreated: po?.dateCreated
        ? `${handleValidDate(po?.dateCreated)}, ${handleValidTime(
            po?.dateCreated
          )}`
        : '',
      datePaid: po?.datePaid
        ? `${handleValidDate(po?.datePaid)}, ${handleValidTime(po?.datePaid)}`
        : '',
      status: statusForTable(po?.status),
      networkType: statusForNetwork(po?.networkType)
    }));

    return dataForExport;
  }
);

export const exportLicences = createAsyncThunk(
  'directorInfo/withdrawals/licences',
  async (params: URLSearchParams) => {
    const plainParams: Record<string, string | null> = {};
    params.forEach((value, key) => {
      plainParams[key] = value === '' ? null : value;
    });

    const response = await axiosInstance.get(
      '/director/licences/export',
      plainParams.fromDate !== null && plainParams.toDate !== null
        ? {
            params
          }
        : {}
    );

    const dataForExport = response.data.data.map((po: any) => ({
      id: po?.id,
      user: `${po?.user?.name} ${po?.user?.surname}`,
      price: `${po?.price ? usdFormatter?.format(po?.price) : ''}`,
      paymentAddress: `${po?.paymentAddress}`,

      licence: po?.licence?.name,
      licencePrice: po?.licence?.price,
      licenceDuration: po?.licence?.yearDuration,
      dateCreated: po?.dateCreated
        ? `${handleValidDate(po?.dateCreated)}, ${handleValidTime(
            po?.dateCreated
          )}`
        : '',
      datePaid: po?.datePaid
        ? `${handleValidDate(po?.datePaid)}, ${handleValidTime(po?.datePaid)}`
        : '',
      dateEnd: po?.dateEnd
        ? `${handleValidDate(po?.dateEnd)}, ${handleValidTime(po?.dateEnd)}`
        : '',
      // dateLimitForAutoStake: po?.dateLimitForAutoStake
      //   ? `${handleValidDate(po?.dateLimitForAutoStake)}, ${handleValidTime(
      //       po?.dateLimitForAutoStake
      //     )}`
      //   : '',
      isCancelable: po?.isCancelable ? 'YES' : 'NO',
      status: statusForTable(po?.status),
      networkType: statusForNetwork(po?.networkType),
      orderType: statusForOrder(po?.orderType)
    }));

    return dataForExport;
  }
);

export const exportPackages = createAsyncThunk(
  'directorInfo/withdrawals/packages',
  async (params: URLSearchParams) => {
    const plainParams: Record<string, string | null> = {};
    params.forEach((value, key) => {
      plainParams[key] = value === '' ? null : value;
    });

    const response = await axiosInstance.get(
      '/director/packages/export',
      plainParams.fromDate !== null && plainParams.toDate !== null
        ? {
            params
          }
        : {}
    );

    const dataForExport = response.data.data.map((po: any) => ({
      id: po?.id,
      user: `${po?.user?.name} ${po?.user?.surname}`,
      country: `${po?.user?.address?.country}`,
      price: `${po?.price ? usdFormatter?.format(po?.price) : ''}`,
      package: `${po?.package?.name}`,
      paymentAddress: `${po?.paymentAddress}`,

      dateCreated: po?.dateCreated
        ? `${handleValidDate(po?.dateCreated)}, ${handleValidTime(
            po?.dateCreated
          )}`
        : '',
      datePaid: po?.datePaid
        ? `${handleValidDate(po?.datePaid)}, ${handleValidTime(po?.datePaid)}`
        : '',

      status: statusForTable(po?.status),
      networkType: po?.networkType,
      orderType: statusForOrder(po?.orderType),
      calculationStatus: calculationStatusForTable(po?.calculationStatus)
    }));

    return dataForExport;
  }
);

export const exportHubs = createAsyncThunk(
  'directorInfo/withdrawals/hubs',
  async ({ params, hubType }: { params: URLSearchParams; hubType: number }) => {
    const plainParams: Record<string, string | null> = {};
    params.forEach((value, key) => {
      plainParams[key] = value === '' ? null : value;
    });

    const response = await axiosInstance.get(
      `/director/packages-by-type/export?hubType=${hubType}`,
      plainParams.fromDate !== null && plainParams.toDate !== null
        ? {
            params
          }
        : {}
    );

    const dataForExport = response.data.data.map((po: any) => ({
      id: po?.id,
      user: `${po?.user?.name} ${po?.user?.surname}`,
      country: `${po?.user?.address?.country}`,
      price: `${po?.price ? usdFormatter?.format(po?.price) : ''}`,
      package: `${po?.package?.name}`,
      paymentAddress: `${po?.paymentAddress}`,

      dateCreated: po?.dateCreated
        ? `${handleValidDate(po?.dateCreated)}, ${handleValidTime(
            po?.dateCreated
          )}`
        : '',
      datePaid: po?.datePaid
        ? `${handleValidDate(po?.datePaid)}, ${handleValidTime(po?.datePaid)}`
        : '',

      status: statusForTable(po?.status),
      networkType: po?.networkType,
      orderType: statusForOrder(po?.orderType),
      calculationStatus: calculationStatusForTable(po?.calculationStatus)
    }));

    return {
      dataForExport,
      hubType
    };
  }
);

export const exportWithdrawalHeaders = [
  { label: 'ID', key: 'id' },
  { label: 'User', key: 'user' },
  { label: 'Country', key: 'country' },
  { label: 'Amount', key: 'amount' },
  { label: 'Free Amount', key: 'feeAmount' },
  { label: 'Paid Amount', key: 'paidAmount' },
  { label: 'Wallet', key: 'wallet' },
  { label: 'Network', key: 'networkType' },
  { label: 'Status', key: 'status' },
  { label: 'Date Created', key: 'dateCreated' },
  { label: 'Date Paid', key: 'datePaid' }
];

export const exportLicencesHeaders = [
  { label: 'ID', key: 'id' },
  { label: 'User', key: 'user' },
  { label: 'Price', key: 'price' },
  { label: 'Payment Address', key: 'paymentAddress' },
  { label: 'Network', key: 'networkType' },
  { label: 'Status', key: 'status' },
  { label: 'Date Created', key: 'dateCreated' },
  { label: 'Date Paid', key: 'datePaid' },
  { label: 'Date End', key: 'dateEnd' },
  { label: 'Licence', key: 'licence' },
  { label: 'Licence Price', key: 'licencePrice' },
  { label: 'Is Cancelable', key: 'isCancelable' },
  { label: 'Order', key: 'orderType' }
];

export const exportPackagesHeaders = [
  { label: 'ID', key: 'id' },
  { label: 'User', key: 'user' },
  { label: 'Country', key: 'country' },
  { label: 'Price', key: 'price' },
  { label: 'Package Name', key: 'package' },
  { label: 'Payment Address', key: 'paymentAddress' },
  { label: 'Network', key: 'networkType' },
  { label: 'Status', key: 'status' },
  { label: 'Date Created', key: 'dateCreated' },
  { label: 'Date Paid', key: 'datePaid' },
  { label: 'Order', key: 'orderType' },
  { label: 'Calculation Status', key: 'calculationStatus' }
];

export const exportHubsHeaders = [
  { label: 'ID', key: 'id' },
  { label: 'User', key: 'user' },
  { label: 'Country', key: 'country' },
  { label: 'Price', key: 'price' },
  { label: 'Package Name', key: 'package' },
  { label: 'Payment Address', key: 'paymentAddress' },
  { label: 'Network', key: 'networkType' },
  { label: 'Status', key: 'status' },
  { label: 'Date Created', key: 'dateCreated' },
  { label: 'Date Paid', key: 'datePaid' },
  { label: 'Order', key: 'orderType' },
  { label: 'Calculation Status', key: 'calculationStatus' }
];

export const statusForTable = (status: number) => {
  switch (status) {
    case 1:
      return 'PENDING';
    case 2:
      return 'PAID';
    case 3:
      return 'COMPLETED';
    case 4:
      return 'CANCELED';
    case 5:
      return 'AUTOMATIC PAYOUT IN PROGRESS';
    case 6:
      return 'AUTOMATIC PAYOUT PAID';
    default:
      break;
  }
};

export const statusForNetwork = (networkType: number) => {
  switch (networkType) {
    case 1:
      return 'BEP-20';
    case 2:
      return 'TRC-20';
    case 3:
      return 'ULTORN';

    default:
      break;
  }
};

export const statusForOrder = (orderType: number) => {
  switch (orderType) {
    case 1:
      return 'ORDER';
    case 2:
      return 'PACKAGE';
    case 3:
      return 'LICENCE';
    case 4:
      return 'COMBO';
    default:
      break;
  }
};

const initialState = {
  data: {},
  totalCount: 0,
  loading: false,
  loadingExportWithdrawals: false,
  loadingExportLicences: false,
  loadingExportPackages: false,
  exportWithdrawalsData: [],
  exportLicencesData: [],
  exportPackagesData: [],
  exportPaymentHubs: [],
  exportStackingHubs: [],
  exportGamingHubs: [],
  exportBigWhaleHubs: [],
  exportTravelHubs: [],
  exportFlipMeHubs: [],
  exportTradingHubs: [],
  exportAkashaHubs: []
} as DirectorInfoState;

const DirectorInfoSlice = createSlice({
  name: 'directorInfo',
  initialState,
  reducers: {
    removeWithdrawalForExport(state) {
      state.exportWithdrawalsData = [];
    },
    removeLicencesForExport(state) {
      state.exportLicencesData = [];
    },
    removePackagesForExport(state) {
      state.exportPackagesData = [];
    },

    removeHubsForExport(state) {
      state.exportStackingHubs = [];
      state.exportPaymentHubs = [];
      state.exportGamingHubs = [];
      state.exportBigWhaleHubs = [];
      state.exportTravelHubs = [];
      state.exportFlipMeHubs = [];
      state.exportTradingHubs = [];
      state.exportAkashaHubs = [];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getDirectorInfo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDirectorInfo.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload.info as DirectorInfo;
      state.totalCount = payload.count;
    });
    builder.addCase(getDirectorInfo.rejected, (state) => {
      state.loading = false;
    });

    //export withdrawals
    builder.addCase(exportWithdrawals.pending, (state) => {
      state.loadingExportWithdrawals = true;
    });
    builder.addCase(exportWithdrawals.fulfilled, (state, { payload }) => {
      state.loadingExportWithdrawals = false;
      state.exportWithdrawalsData = payload;
    });
    builder.addCase(exportWithdrawals.rejected, (state) => {
      state.loadingExportWithdrawals = false;
    });

    //export licences

    builder.addCase(exportLicences.pending, (state) => {
      state.loadingExportLicences = true;
    });
    builder.addCase(exportLicences.fulfilled, (state, { payload }) => {
      state.loadingExportLicences = false;
      state.exportLicencesData = payload;
    });
    builder.addCase(exportLicences.rejected, (state) => {
      state.loadingExportLicences = false;
    });

    //export packages

    builder.addCase(exportPackages.pending, (state) => {
      state.loadingExportPackages = true;
    });
    builder.addCase(exportPackages.fulfilled, (state, { payload }) => {
      state.loadingExportPackages = false;
      state.exportPackagesData = payload;
    });
    builder.addCase(exportPackages.rejected, (state) => {
      state.loadingExportPackages = false;
    });

    //export hubs

    builder.addCase(exportHubs.pending, (state) => {
      state.loadingExportPackages = true;
    });
    builder.addCase(exportHubs.fulfilled, (state, { payload }) => {
      state.loadingExportPackages = false;

      if (payload.hubType === HubsEnum.STACKING) {
        state.exportStackingHubs = payload.dataForExport;
        state.exportPaymentHubs = [];
        state.exportGamingHubs = [];
        state.exportBigWhaleHubs = [];
        state.exportTravelHubs = [];
        state.exportFlipMeHubs = [];
        state.exportTradingHubs = [];
        state.exportAkashaHubs = [];
      } else if (payload.hubType === HubsEnum.PAYMENT) {
        state.exportStackingHubs = [];
        state.exportPaymentHubs = payload.dataForExport;
        state.exportGamingHubs = [];
        state.exportBigWhaleHubs = [];
        state.exportTravelHubs = [];
        state.exportFlipMeHubs = [];
        state.exportTradingHubs = [];
        state.exportAkashaHubs = [];
      } else if (payload.hubType === HubsEnum.GAMING) {
        state.exportStackingHubs = [];
        state.exportPaymentHubs = [];
        state.exportGamingHubs = payload.dataForExport;
        state.exportBigWhaleHubs = [];
        state.exportTravelHubs = [];
        state.exportFlipMeHubs = [];
        state.exportTradingHubs = [];
        state.exportAkashaHubs = [];
      } else if (payload.hubType === HubsEnum.BIG_WHALE) {
        state.exportStackingHubs = [];
        state.exportPaymentHubs = [];
        state.exportGamingHubs = [];
        state.exportBigWhaleHubs = payload.dataForExport;
        state.exportTravelHubs = [];
        state.exportFlipMeHubs = [];
        state.exportTradingHubs = [];
        state.exportAkashaHubs = [];
      } else if (payload.hubType === HubsEnum.TRAVELUTION) {
        state.exportStackingHubs = [];
        state.exportPaymentHubs = [];
        state.exportGamingHubs = [];
        state.exportBigWhaleHubs = [];
        state.exportTravelHubs = payload.dataForExport;
        state.exportFlipMeHubs = [];
        state.exportTradingHubs = [];
        state.exportAkashaHubs = [];
      } else if (payload.hubType === HubsEnum.FLIP_ME) {
        state.exportStackingHubs = [];
        state.exportPaymentHubs = [];
        state.exportGamingHubs = [];
        state.exportBigWhaleHubs = [];
        state.exportTravelHubs = [];
        state.exportFlipMeHubs = payload.dataForExport;
        state.exportTradingHubs = [];
        state.exportAkashaHubs = [];
      } else if (payload.hubType === HubsEnum.TRADING) {
        state.exportStackingHubs = [];
        state.exportPaymentHubs = [];
        state.exportGamingHubs = [];
        state.exportBigWhaleHubs = [];
        state.exportTravelHubs = [];
        state.exportFlipMeHubs = [];
        state.exportTradingHubs = payload.dataForExport;
        state.exportAkashaHubs = [];
      } else if (payload.hubType === HubsEnum.AKASHA) {
        state.exportStackingHubs = [];
        state.exportPaymentHubs = [];
        state.exportGamingHubs = [];
        state.exportBigWhaleHubs = [];
        state.exportTravelHubs = [];
        state.exportFlipMeHubs = [];
        state.exportTradingHubs = [];
        state.exportAkashaHubs = payload.dataForExport;
      }
    });
    builder.addCase(exportHubs.rejected, (state) => {
      state.loadingExportPackages = false;
    });
  }
});

export const { removeWithdrawalForExport } = DirectorInfoSlice.actions;

export const { removeLicencesForExport } = DirectorInfoSlice.actions;

export const { removePackagesForExport } = DirectorInfoSlice.actions;

export const { removeHubsForExport } = DirectorInfoSlice.actions;

export default DirectorInfoSlice.reducer;
