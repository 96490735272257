import { Dispatch, FC, SetStateAction } from 'react';
import { Button, DropdownItem } from 'reactstrap';
import { handleMultipleModalsWithOneState } from '../../../../helpers/helpers';
import { Modal } from '../../../../Components/Custom/modal';
import { OpenedModal } from '../types';
import { useAppDispatch } from '../../../../app/hooks';
import { stornoTravelutionSubscription } from '../slice';

export const Storno: FC<{
  setIsOpen: Dispatch<SetStateAction<number>>;
}> = ({ setIsOpen }) => {
  return (
    <DropdownItem
      tag='a'
      href='/'
      className='w-100'
      onClick={(e) => {
        e.preventDefault();
      }}>
      <div
        style={{ width: '100%' }}
        onClick={() => setIsOpen(OpenedModal.STORNO)}>
        Storno
      </div>
    </DropdownItem>
  );
};

export const StornoModal: FC<{
  id: number;
  setIsOpen: Dispatch<SetStateAction<number>>;
  isOpen: number;
}> = ({ id, isOpen, setIsOpen }) => {
  const dispatch = useAppDispatch();

  return (
    <Modal
      isOpen={isOpen === OpenedModal.STORNO}
      setIsOpen={() => handleMultipleModalsWithOneState(isOpen, setIsOpen)}
      title='Storno'
      actions={
        <>
          <Button
            color='primary'
            outline
            onClick={() => setIsOpen(OpenedModal.CLOSED)}
            className='m-1'>
            Cancel
          </Button>
          <Button
            color='primary'
            className='m-1'
            onClick={() => {
              setIsOpen(OpenedModal.CLOSED);
              dispatch(stornoTravelutionSubscription(id));
            }}>
            Yes
          </Button>
        </>
      }>
      <div className='mb-3'>
        <div className='avatar-lg mx-auto'>
          <div className='avatar-title bg-light text-primary display-5 rounded-circle'>
            <i className='ri-error-warning-fill '></i>
          </div>
        </div>
      </div>
      <h2>Are you sure?</h2>
      <p>You won’t be able to revert this!</p>
    </Modal>
  );
};
