import {
  handleValidDate,
  handleValidTime
} from '../../../../../helpers/helpers';
import { NetworkType } from '../../../PackageOrders/types';

export const columns = [
  {
    id: 1,
    Header: 'Transfer ID',
    accessor: 'id',
    Cell: (cell: any) => {
      return cell?.value;
    }
  },
  {
    id: 2,
    Header: 'Date Created',
    accessor: 'dateCreated',
    Cell: (cell: any) => {
      if (cell.value) {
        return (
          <>
            {handleValidDate(cell.value)},
            <small className='text-muted'> {handleValidTime(cell.value)}</small>
          </>
        );
      }
    }
  },
  {
    id: 3,
    Header: 'Date Paid',
    accessor: 'datePaid',
    Cell: (cell: any) => {
      if (cell.value) {
        return (
          <>
            {handleValidDate(cell.value)},
            <small className='text-muted'> {handleValidTime(cell.value)}</small>
          </>
        );
      }
    }
  },
  {
    id: 4,
    Header: 'Paid amount',
    accessor: 'price',
    Cell: (cell: any) => {
      return <>{cell?.value}</>;
    }
  },
  {
    id: 5,
    Header: 'Received amount',
    accessor: 'receivedAmount',
    Cell: (cell: any) => {
      return <>{cell?.value}</>;
    }
  },
  {
    id: 6,
    Header: 'Type',
    accessor: 'type',
    Cell: (cell: any) => {
      return <>{cell?.value}</>;
    }
  },
  {
    id: 7,
    Header: 'Currency',
    accessor: 'networkCurrencyType',
    Cell: (cell: any) => {
      return <>{cell?.value?.toUpperCase()}</>;
    }
  },
  {
    id: 9,
    Header: 'Wallet',
    accessor: 'paymentAddress',
    Cell: (cell: any) => {
      return <>{cell?.value}</>;
    }
  },
  {
    id: 10,
    Header: 'Network type',
    accessor: 'networkType',
    Cell: (cell: any) => {
      switch (cell.value) {
        case NetworkType.BEP_20:
          return <div>BEP-20</div>;

        case NetworkType.TRC_20:
          return <div>TRC-20</div>;

        case NetworkType.Ultron:
          return <div>Ultron</div>;

        default:
          return <div></div>;
      }
    }
  },

  {
    id: 11,
    Header: 'Status',
    accessor: 'status',
    Cell: (cell: any) => {
      switch (cell.value) {
        case 1:
          return (
            <span className='badge text-uppercase badge-soft-warning'>
              Waiting for Payment
            </span>
          );

        case 2:
          return (
            <span className='badge text-uppercase badge-soft-success'>
              Paid
            </span>
          );

        case 3:
          return (
            <span className='badge text-uppercase badge-soft-info'>
              Ineligible
            </span>
          );
      }
    }
  }
];
