import { FC } from 'react';
import { RenderFilters } from '../../../Components/Custom/RenderFilters';
import { RenderSorting } from '../../../Components/Custom/RenderSorting';
import {
  INPUT_TYPE_DATE_RANGE,
  INPUT_TYPE_SEARCH,
  INPUT_TYPE_SELECT
} from '../../../helpers/helpers';

interface FiltersProps {
  params: URLSearchParams;
  setParams: any;
}

export const Filters: FC<FiltersProps> = ({ params, setParams }) => {
  const statuses = [
    { label: 'Pending', value: '1' },
    { label: 'Paid', value: '2' },
    { label: 'Canceled', value: '3' },
    { label: 'Canceled by user', value: '4' }
  ];

  const sortOrderOptions = [
    { label: 'Ascending', value: 'ASC' },
    { label: 'Descending', value: 'DESC' }
  ];

  const sortByOptions = [
    { label: 'User Package ID', value: 'userPackageId' },
    { label: 'User ID', value: 'userId' },
    { label: 'User Name', value: 'userName' },
    { label: 'User Surname', value: 'userSurname' },
    { label: 'Package Name', value: 'packageName' },
    { label: 'Price', value: 'price' },
    { label: 'Date Created', value: 'dateCreated' },
    { label: 'Date Paid', value: 'datePaid' },
    { label: 'Status', value: 'status' }
  ];

  const sortingInputs = [
    { param: 'sortBy', type: INPUT_TYPE_SELECT, options: sortByOptions },
    { param: 'sortOrder', type: INPUT_TYPE_SELECT, options: sortOrderOptions }
  ];

  const filterInputs = [
    {
      param: 'userPackageId',
      type: INPUT_TYPE_SEARCH,
      placeholder: 'Search Order ID'
    },

    {
      param: 'userId',
      type: INPUT_TYPE_SEARCH,
      placeholder: 'Search User ID'
    },

    {
      param: 'paymentAddress',
      type: INPUT_TYPE_SEARCH,
      placeholder: 'Search Payment Address'
    },

    { param: '', type: INPUT_TYPE_DATE_RANGE },

    {
      param: 'status',
      type: INPUT_TYPE_SELECT,
      placeholder: 'Status',
      options: statuses
    }
  ];

  return (
    <>
      <RenderSorting
        sortingInputs={sortingInputs}
        searchParams={params}
        setSearchParams={setParams}
      />
      <RenderFilters
        filterInputs={filterInputs}
        searchParams={params}
        setSearchParams={setParams}
      />
    </>
  );
};
