import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { axiosInstance } from '../../../helpers/api_helper';
import { TravelutionSubscriptionType } from './types';
import {
  handleValidDate,
  handleValidTime,
  usdFormatter
} from '../../../helpers/helpers';
import { statusForNetwork } from '../DirectorInfo/slice';

export const getTravelutionSubscriptions = createAsyncThunk(
  'travelutionSubscriptions/fetch',
  async (params: URLSearchParams) => {
    const response = await axiosInstance.get('/trevolution-subscription/user', {
      params
    });
    return response.data.data;
  }
);

export const exportTravelutionSubscriptions = createAsyncThunk(
  'travelutionSubscriptions/exportTravelutionSubscriptions',
  async (params: URLSearchParams) => {
    const response = await axiosInstance.get(
      `/trevolution-subscription/user/export`,
      {
        params
      }
    );

    const dataForExport = response.data.data.userTrevolutionSubscriptions.map(
      (po: any) => ({
        subscriptionId: po?.id,
        userId: po?.user?.id,
        user: `${po?.user?.name} ${po?.user?.surname}`,
        subscriptionName: `${po?.trevolutionSubscription?.name}`,
        amount: `${po?.price ? usdFormatter?.format(po?.price) : ''}`,
        paymentAddress: po?.paymentAddress || '',
        network: statusForNetwork(po?.networkType),
        orderDate: po?.dateCreated
          ? `${handleValidDate(po?.dateCreated)}, ${handleValidTime(
              po?.dateCreated
            )}`
          : '',
        paymentDate: po?.datePaid
          ? `${handleValidDate(po?.datePaid)}, ${handleValidTime(po?.datePaid)}`
          : '',

        endedDate: po?.dateEnd
          ? `${handleValidDate(po?.dateEnd)}, ${handleValidTime(po?.dateEnd)}`
          : '---',
        startDate: po?.dateStart
          ? `${handleValidDate(po?.dateStart)}, ${handleValidTime(
              po?.dateStart
            )}`
          : '---',
        status: statusForTable(po?.status)
      })
    );

    return dataForExport;
  }
);

export const updateTravelutionSubscriptionToPaid = createAsyncThunk(
  'licenses/updateTravelutionSubscriptionToPaid',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `/trevolution-subscription/user/status/paid`,
        {
          id
        }
      );
      toast.success('Travelution Subscription successfully paid');

      return response.data.data;
    } catch (error: any) {
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const updateTravelutionSubscriptionToPaidNoBonus = createAsyncThunk(
  'licenses/updateTravelutionSubscriptionToPaidNoBonus',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `/trevolution-subscription/user/status/paid/no-bonus`,
        {
          id
        }
      );
      toast.success('Travelution Subscription successfully paid with no bonus');

      return response.data.data;
    } catch (error: any) {
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const stornoTravelutionSubscription = createAsyncThunk(
  'licenses/stornoTravelutionSubscription',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `/trevolution-subscription/user/status/canceled`,
        {
          id
        }
      );
      toast.success('Subscription successfully canceled');
      return response.data.data;
    } catch (error: any) {
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const exportHeaders = [
  { label: 'Subscription ID', key: 'subscriptionId' },
  { label: 'User ID', key: 'userId' },
  { label: 'User', key: 'user' },
  { label: 'Subscription Name', key: 'subscriptionName' },
  { label: 'Amount', key: 'amount' },
  { label: 'Payment Address', key: 'paymentAddress' },
  { label: 'Network', key: 'network' },
  { label: 'Order Date', key: 'orderDate' },
  { label: 'Payment Date', key: 'paymentDate' },
  { label: 'Start Date', key: 'startDate' },
  { label: 'End Date', key: 'endedDate' },
  { label: 'Status', key: 'status' }
];

export const statusForTable = (status: number) => {
  switch (status) {
    case 1:
      return 'PENDING';
    case 2:
      return 'PAID';
    case 3:
      return 'CANCELED';
    case 4:
      return 'CANCELED BY USER';

    default:
      break;
  }
};

export const licenceType = (status: number) => {
  switch (status) {
    case 1:
      return 'ORDER';
    case 2:
      return 'PACKAGE';
    case 3:
      return 'LICENCE';
    case 4:
      return 'COMBO';
    case 7:
      return 'TRAVELUTION SUBSCRIPTION';

    default:
      break;
  }
};

export interface TravelutionSubscriptionStateInterface {
  data: TravelutionSubscriptionType[];
  loading: boolean;
  loadingExport: boolean;
  totalCount: number;
  dataForExport: [];
}

const initialState = {
  data: [],
  totalCount: 0,
  loading: false,
  loadingExport: false,
  dataForExport: []
} as TravelutionSubscriptionStateInterface;

const travelutionSubscriptionSlice = createSlice({
  name: 'travelutionSubscription',
  initialState,
  reducers: {
    removeTravelutionSubscriptionsForExport(state) {
      state.dataForExport = [];
    }
    // fill in primary logic here
  },
  extraReducers: (builder) => {
    builder.addCase(getTravelutionSubscriptions.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getTravelutionSubscriptions.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.data =
          payload.userTrevolutionSubscriptions as TravelutionSubscriptionType[];
        state.totalCount = payload.count;
      }
    );
    builder.addCase(getTravelutionSubscriptions.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(exportTravelutionSubscriptions.pending, (state) => {
      state.loadingExport = true;
    });
    builder.addCase(
      exportTravelutionSubscriptions.fulfilled,
      (state, { payload }) => {
        state.loadingExport = false;
        state.dataForExport = payload;
      }
    );
    builder.addCase(exportTravelutionSubscriptions.rejected, (state) => {
      state.loadingExport = false;
    });

    builder.addCase(updateTravelutionSubscriptionToPaid.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      updateTravelutionSubscriptionToPaid.fulfilled,
      (state, { payload }) => {
        state.loading = false;

        const index = state.data.findIndex((p) => p.id === payload.id);
        state.data[index] = payload;
      }
    );
    builder.addCase(updateTravelutionSubscriptionToPaid.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(
      updateTravelutionSubscriptionToPaidNoBonus.pending,
      (state) => {
        state.loading = true;
      }
    );
    builder.addCase(
      updateTravelutionSubscriptionToPaidNoBonus.fulfilled,
      (state, { payload }) => {
        state.loading = false;

        const index = state.data.findIndex((p) => p.id === payload.id);
        state.data[index] = payload;
      }
    );
    builder.addCase(
      updateTravelutionSubscriptionToPaidNoBonus.rejected,
      (state) => {
        state.loading = false;
      }
    );

    builder.addCase(stornoTravelutionSubscription.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      stornoTravelutionSubscription.fulfilled,
      (state, { payload }) => {
        state.loading = false;

        const index = state.data.findIndex((p) => p.id === payload.id);
        state.data[index] = payload;
      }
    );
    builder.addCase(stornoTravelutionSubscription.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const { removeTravelutionSubscriptionsForExport } =
  travelutionSubscriptionSlice.actions;

export default travelutionSubscriptionSlice.reducer;
