import moment from 'moment';
import { Dispatch, SetStateAction } from 'react';

//STAKING
import sunSeeker from '../assets/customAssets/hubImages/sunSeeker.jpg';
import cosmicWhale from '../assets/customAssets/hubImages/cosmicWhale.jpg';
import galaxyExplorer from '../assets/customAssets/hubImages/galaxyExplorer.jpg';
import solarRanger from '../assets/customAssets/hubImages/solarRanger.jpg';
import spaceSurfer from '../assets/customAssets/hubImages/spaceSurfer.jpg';
import starCatcher from '../assets/customAssets/hubImages/starCatcher.jpg';
import moonWalker from '../assets/customAssets/hubImages/moonWalker.png';

//GAMING
import magicUnicorn from '../assets/customAssets/gamingHubImages/magicUnicorn.jpg';
import mysticLion from '../assets/customAssets/gamingHubImages/mysticLion.jpg';
import mightyDragon from '../assets/customAssets/gamingHubImages/mightyDragon.jpg';
import risingPhoenix from '../assets/customAssets/gamingHubImages/risingPhoenix.jpg';
import ragingBull from '../assets/customAssets/gamingHubImages/ragingBull.jpg';
import wealthyTiger from '../assets/customAssets/gamingHubImages/wealthyTiger.jpg';
import luckyApe from '../assets/customAssets/gamingHubImages/luckyApe.jpg';
import wiseElephant from '../assets/customAssets/gamingHubImages/wiseElephant.jpg';
import fortunePanda from '../assets/customAssets/gamingHubImages/fortunePanda.jpg';

//PAYMENT
import paradiseIsland from '../assets/customAssets/paymentHubImages/paradiseIsland.png';
import dreamCastle from '../assets/customAssets/paymentHubImages/dreamCastle.png';
import crystalSkyscraper from '../assets/customAssets/paymentHubImages/crystalSkyscraper.png';
import plazaHotel from '../assets/customAssets/paymentHubImages/plazaHotel.png';
import royalMansion from '../assets/customAssets/paymentHubImages/royalMansion.png';
import luxuryVilla from '../assets/customAssets/paymentHubImages/luxuryVilla.png';
import skylinePenthouse from '../assets/customAssets/paymentHubImages/skylinePenthouse.png';
import tropicalBeachHouse from '../assets/customAssets/paymentHubImages/tropicalBeachHouse.png';
import modernApartment from '../assets/customAssets/paymentHubImages/modernApartment.png';
import { CurrencyType } from '../pages/Dashboard/PackageOrders/types';

export const handleSetUrlSearchParams = (
  key: string,
  value: string,
  setSearchParams: any
) => {
  setSearchParams((prevParams: URLSearchParams) => {
    if (value === null || value === undefined || value === '') {
      prevParams.delete(key);
    } else {
      prevParams.set(key, value);
    }
    return prevParams;
  });
};

export const handleFilterChange = (
  key: string,
  value: string,
  setSearchParams: any
) => {
  setSearchParams((prevParams: URLSearchParams) => {
    if (value === null || value === undefined || value === '') {
      prevParams.delete(key);
    } else {
      prevParams.set(key, value);
      prevParams.set('numberOfPage', '1');
    }
    return prevParams;
  });
};

export const handleDateRangeChange = (
  value: Date[],
  setSearchParams: any,
  noPagination?: boolean,
  hasTime?: boolean
) => {
  let fromDate: string = '';
  let toDate: string = '';

  if (value.length === 2) {
    if (hasTime) {
      fromDate = moment(value[0]).format('YYYY-MM-DDTHH:mm:ss');
      toDate = moment(value[1]).format('YYYY-MM-DDTHH:mm:ss');
    } else {
      fromDate = moment(value[0]).startOf('day').toISOString();
      toDate = moment(value[1]).endOf('day').toISOString();
    }

    if (fromDate && toDate) {
      setSearchParams((prevParams: URLSearchParams) => {
        prevParams.set('fromDate', fromDate);
        prevParams.set('toDate', toDate);
        if (!noPagination) prevParams.set('numberOfPage', '1');

        return prevParams;
      });
    }
  }
};

export const handleDateChange = (
  param: string,
  value: Date[],
  setSearchParams: any,
  noPagination?: boolean,
  hasTime?: boolean
) => {
  let date: string = '';

  if (hasTime) {
    date = moment(value[0]).format('YYYY-MM-DDTHH:mm:ss');
  } else {
    date = moment(value[0]).startOf('day').toISOString();
  }

  if (date) {
    setSearchParams((prevParams: URLSearchParams) => {
      prevParams.set(param, date);

      if (!noPagination) prevParams.set('numberOfPage', '1');

      return prevParams;
    });
  }
};

export const handleValidDate = (date: string) => {
  const newDate = moment.utc(date);

  return newDate.format('DD MMM Y');
};

export const handleValidTime = (time: string) => {
  const date = moment.utc(time);

  return date.format('h:mm A');
};

export const date = new Date();

export const dateNow = date;

export const dateNowPlusOneDay = new Date().setDate(date.getDate() + 1);

export const handleMultipleModalsWithOneState = (
  whatIsOpen: number,
  setState: Dispatch<SetStateAction<number>>
) => {
  if (whatIsOpen) {
    setState(0);
  }
};

export const usdFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});

export const formatDecimalNumber = (input: number) => {
  // Convert input to a string if it's not already
  const inputString = typeof input === 'number' ? input.toFixed(8) : input;

  // Split the string into integer and decimal parts
  const [integerPart, decimalPart] = inputString.split('.');

  // Truncate decimal part to 4 places
  const truncatedDecimalPart = decimalPart ? decimalPart.slice(0, 4) : '';

  // Join the parts with a comma
  const formattedValue = `${integerPart},${truncatedDecimalPart}`;

  return formattedValue;
};

export const getInitials = (str: string) => {
  const results: Array<string> = [];
  const wordArray = str.split(' ');
  wordArray.forEach((e) => {
    results.push(e[0]);
  });
  return results.join('');
};

export const NO_INFO = '---';

export const INPUT_TYPE_SEARCH = 'search';
export const INPUT_TYPE_SELECT = 'select';
export const INPUT_TYPE_DATE_RANGE = 'dateRange';
export const INPUT_TYPE_DATE = 'date';
export const INPUT_TYPE_AMOUNT = 'amount';

export const setDocumentTitle = (pageTitle: string) =>
  (document.title = `${pageTitle} | Ultron Admin`);

export function ValidateEmail(mail: string) {
  if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
    return true;
  }
  return false;
}

export const formatterCurrency = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  unitDisplay: 'short'
  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const formatUSDT = new Intl.NumberFormat('de-DE');

export const formatUSD = (value: any) => {
  const isWholeNumber = Number.isInteger(value);

  if (isWholeNumber) {
    const comma =
      value && value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    return `${comma},00`;
  } else {
    const parsedNumber = value?.toFixed(2);

    let convertedNumber = parsedNumber?.toString();

    const num = convertedNumber?.split('.');

    const splitedNumber = convertedNumber && num[1];

    convertedNumber = convertedNumber?.replace('.', ',');

    convertedNumber = convertedNumber
      ?.toString()
      ?.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    if (splitedNumber?.length === 1) {
      return `${convertedNumber}0`;
    } else {
      return convertedNumber;
    }
  }
};

export const formatUSDNoDecimals = (value: any) => {
  const isWholeNumber = Number.isInteger(value);

  if (isWholeNumber) {
    const comma =
      value && value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    return `${comma}`;
  } else {
    const parsedNumber = value?.toFixed(2);

    let convertedNumber = parsedNumber?.toString();

    const num = convertedNumber?.split('.');

    const splitedNumber = convertedNumber && num[1];

    convertedNumber = convertedNumber?.replace('.', ',');

    convertedNumber = convertedNumber
      ?.toString()
      ?.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    if (splitedNumber?.length === 1) {
      return `${convertedNumber}0`;
    } else {
      return convertedNumber;
    }
  }
};

export const showImages = (packageId: number) => {
  switch (packageId) {
    //Staking
    case 28:
      return solarRanger;
    case 29:
      return starCatcher;
    case 30:
      return spaceSurfer;
    case 31:
      return galaxyExplorer;
    case 32:
      return sunSeeker;
    case 33:
      return moonWalker;
    case 34:
      return cosmicWhale;

    //Gaming
    case 35:
      return luckyApe;
    case 36:
      return wiseElephant;
    case 37:
      return fortunePanda;
    case 38:
      return ragingBull;
    case 39:
      return wealthyTiger;
    case 40:
      return risingPhoenix;
    case 41:
      return mightyDragon;
    case 42:
      return mysticLion;
    case 43:
      return magicUnicorn;

    //Gaming Hidden
    case 44:
      return wealthyTiger;
    case 45:
      return risingPhoenix;
    case 46:
      return mightyDragon;
    case 47:
      return mysticLion;
    case 48:
      return magicUnicorn;
    case 49:
      return ragingBull;

    //Payment
    case 50:
      return modernApartment;
    case 51:
      return tropicalBeachHouse;
    case 52:
      return skylinePenthouse;
    case 53:
      return luxuryVilla;
    case 54:
      return royalMansion;
    case 55:
      return plazaHotel;
    case 56:
      return crystalSkyscraper;
    case 57:
      return dreamCastle;
    case 58:
      return paradiseIsland;

    //Payment Hidden
    case 59:
      return modernApartment;
    case 60:
      return tropicalBeachHouse;
    case 61:
      return skylinePenthouse;
    case 62:
      return luxuryVilla;
    case 63:
      return royalMansion;
    case 64:
      return plazaHotel;
    case 65:
      return crystalSkyscraper;
    case 66:
      return dreamCastle;
    case 67:
      return paradiseIsland;

    default:
      return luckyApe;
  }
};

export const dateDifferenceInDaysFromToday = (date: any) => {
  const start = moment(new Date());

  const end = moment(date);

  const duration = moment.duration(end.diff(start));

  return duration.asDays().toFixed(0);
};

export const paramsForExport = (searchParams: URLSearchParams) => {
  const params = searchParams;
  params.delete('countPerPage');
  params.delete('numberOfPage');

  return params;
};

export const dateForExport = moment().format('DD-MM-YYYY');

export const calculatePercentage = (current: number, required: number) => {
  const data = (100 * current) / required;

  return Number(data.toFixed(0));
};

export const BP = 'BP';
export const BV = 'BV';
export const UV = 'UV';
export const PO = 'PO';

export const formatVP = (value: number = 0) => {
  let convertedNumber = value?.toString();

  if (convertedNumber?.includes('.')) {
    let num = convertedNumber?.split('.');
    let splitedNumber = convertedNumber && num[1];

    if (splitedNumber?.length === 1) {
      convertedNumber = convertedNumber
        ?.toString()
        ?.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

      return `${convertedNumber}0`;
    } else {
      convertedNumber = convertedNumber
        ?.toString()
        ?.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      return convertedNumber;
    }
  } else {
    convertedNumber = convertedNumber
      ?.toString()
      ?.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    return convertedNumber;
  }
};

export const SomeUserNameInitials = (name: string, surname: string) => {
  const nameSurname = name?.charAt(0) + surname?.charAt(0);
  return nameSurname;
};

export function numberInRange(num: number, low: number, high: number) {
  if (num >= low && num <= high) {
    return true;
  }

  return false;
}

export const USDT = 'USDT';
export const CLUB = 'CLUB USDT';
export const CP = 'CP';
export const RP = 'RP';
export const ULX = 'wULX';
export const LP = 'LP';
export const MIX = 'MIX';
export const USDC = 'USDC';

export const currencyTypeRender = (currencyType: CurrencyType) => {
  switch (currencyType) {
    case CurrencyType.BANK_TRANSFER:
      return `${USDT} (tether)`;
    case CurrencyType.SHOPPING_POINTS:
      return CLUB;
    case CurrencyType.REWARD_POINTS:
      return USDT;
    case CurrencyType.LOYALTY_POINTS:
      return LP;
    case CurrencyType.USDC:
      return `${USDC}`;
    case CurrencyType.MIX:
      return 'MULTIPLE';
  }
};
