import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { axiosInstance } from '../../../helpers/api_helper';
import { LicenseType } from './types';
import {
  handleValidDate,
  handleValidTime,
  usdFormatter
} from '../../../helpers/helpers';
import { statusForNetwork } from '../DirectorInfo/slice';

export const getLicenses = createAsyncThunk(
  'licenses/fetch',
  async (params: URLSearchParams) => {
    const response = await axiosInstance.get('/licence/user', {
      params
    });
    return response.data.data;
  }
);

export const updateLicenseToPaid = createAsyncThunk(
  'licenses/updateLicenseToPaid',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`/licence/user/status/paid`, {
        id
      });
      toast.success(response.data.message);
      return response.data.data.userLicence as LicenseType;
    } catch (error: any) {
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const stornoLicence = createAsyncThunk(
  'licenses/stornoLicence',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `/licence/user/status/canceled`,
        {
          id
        }
      );
      toast.success('Licence successfully updated');
      return response.data.data.userLicence as LicenseType;
    } catch (error: any) {
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const exportLicences = createAsyncThunk(
  'packageOrders/exportLicences',
  async (params: URLSearchParams) => {
    const response = await axiosInstance.get(`/licence/user/export`, {
      params
    });

    const dataForExport = response.data.data.userLicences.map((po: any) => ({
      licenceId: po?.id,
      userId: po?.user?.id,
      user: `${po?.user?.name} ${po?.user?.surname}`,
      type: licenceType(po?.orderType),
      amount: `${po?.price ? usdFormatter?.format(po?.price) : ''}`,
      paymentAddress: po?.paymentAddress || '',
      network: statusForNetwork(po?.networkType),
      orderDate: po?.dateCreated
        ? `${handleValidDate(po?.dateCreated)}, ${handleValidTime(
            po?.dateCreated
          )}`
        : '',
      paymentDate: po?.datePaid
        ? `${handleValidDate(po?.datePaid)}, ${handleValidTime(po?.datePaid)}`
        : '',

      endedDate: po?.dateEnd
        ? `${handleValidDate(po?.dateEnd)}, ${handleValidTime(po?.dateEnd)}`
        : '---',
      status: statusForTable(po?.status)
    }));

    return dataForExport;
  }
);

export const exportHeaders = [
  { label: 'Licence ID', key: 'licenceId' },
  { label: 'User ID', key: 'userId' },
  { label: 'User', key: 'user' },
  { label: 'Type', key: 'type' },
  { label: 'Amount', key: 'amount' },
  { label: 'Payment Address', key: 'paymentAddress' },
  { label: 'Network', key: 'network' },
  { label: 'Order Date', key: 'orderDate' },
  { label: 'Payment Date', key: 'paymentDate' },
  { label: 'BO Extended To', key: 'endedDate' },
  { label: 'Status', key: 'status' }
];

export const statusForTable = (status: number) => {
  switch (status) {
    case 1:
      return 'PENDING';
    case 2:
      return 'PAID';
    case 3:
      return 'CANCELED';
    case 4:
      return 'CANCELED BY USER';

    default:
      break;
  }
};

export const licenceType = (status: number) => {
  switch (status) {
    case 1:
      return 'ORDER';
    case 2:
      return 'PACKAGE';
    case 3:
      return 'LICENCE';
    case 4:
      return 'COMBO';

    default:
      break;
  }
};

export interface LicensesStateInterface {
  data: LicenseType[];
  loading: boolean;
  loadingExport: boolean;
  totalCount: number;
  dataForExport: [];
}

const initialState = {
  data: [],
  totalCount: 0,
  loading: false,
  loadingExport: false,
  dataForExport: []
} as LicensesStateInterface;

const licensesSlice = createSlice({
  name: 'licenses',
  initialState,
  reducers: {
    removeLicencesForExport(state) {
      state.dataForExport = [];
    }
    // fill in primary logic here
  },
  extraReducers: (builder) => {
    builder.addCase(getLicenses.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLicenses.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload.userLicences as LicenseType[];
      state.totalCount = payload.count;
    });
    builder.addCase(getLicenses.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updateLicenseToPaid.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateLicenseToPaid.fulfilled, (state, { payload }) => {
      state.loading = false;

      const index = state.data.findIndex((p) => p.id === payload.id);
      state.data[index] = payload;
    });
    builder.addCase(updateLicenseToPaid.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(stornoLicence.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(stornoLicence.fulfilled, (state, { payload }) => {
      state.loading = false;

      const index = state.data.findIndex((p) => p.id === payload.id);
      state.data[index] = payload;
    });
    builder.addCase(stornoLicence.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(exportLicences.pending, (state) => {
      state.loadingExport = true;
    });
    builder.addCase(exportLicences.fulfilled, (state, { payload }) => {
      state.loadingExport = false;
      state.dataForExport = payload;
    });
    builder.addCase(exportLicences.rejected, (state) => {
      state.loadingExport = false;
    });
  }
});

export const { removeLicencesForExport } = licensesSlice.actions;

export default licensesSlice.reducer;
